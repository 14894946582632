@import url("https://fonts.googleapis.com/css2?family=Caveat:wght@400;500;600;700&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    @apply bg-primary text-light font-sans m-0 p-0 h-[100vh];
  }

  input {
    @apply bg-light text-dark rounded border-2 border-secondary;
  }
  textarea {
    @apply bg-light text-dark rounded border-2 border-secondary;
  }

  .buttons {
    @apply w-full shadow-2xl bg-tertiary text-light font-semibold rounded-lg;
  }

  .form-items {
    @apply flex flex-col;
  }

  .confirmation {
    @apply text-dark font-semibold bg-quinary border-2 border-secondary rounded-lg;
  }

  .menu-items {
    @apply w-[200px] text-center mb-4 p-4 cursor-pointer;
  }

  .error-message {
    @apply text-red-400;
  }
}
